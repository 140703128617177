.form-wrapper-contact {
  max-width: 900px;
  margin: 2em auto;
  background: white;
  border-radius: 0.5em;
  // box-shadow: 1px 1px 1px black;
  form {

    h2 {
      font-size: 1.5em;
      margin: 1em 0;
    }
    padding: 1em;
    color: black;

    p {
      // font-family: Helvetica;
    }
    button {
      background: #0a4e61;
      color: white;
      width: 5em;
    }
  }
}
